<template>
  <div class="authority-manage">
    <div class>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-dark" @click="$router.back(-1)">
              <strong>
                <i class="fa fa-chevron-left mr5"></i>
                {{ $t("back") }}
              </strong>
            </a>
          </li>
          <li class="breadcrumb-item text-grey" aria-current="page">{{ $t("nav.student") }}</li>
          <li class="breadcrumb-item text-dark" aria-current="page">{{ $t("student.edit_right") }}</li>
        </ol>
      </nav>
    </div>
    <div class="authority-content">
      <el-form :model="formData" :rules="rules" ref="authorForm" size="small">
      <div class="content-title">{{ $t("authority.right_det") }}</div>
      <div class="section">
        <div class="section-title clearfix">
          <span class="fl circle">01</span>
          <span class="fl text">{{$t("authority.basic_info")}}</span>
        </div>
        <div class="section-wrapper">
          <div class="clearfix">
            <div class="fl info-item">
              <span>{{$t("authority.student")}}：</span>
              <span>{{$route.query.name}}</span>
            </div>
            <div class="fl info-item">
              <span>{{$t("authority.department")}}：</span>
              <span>{{$route.query.department}}</span>
            </div>
            <div class="fl info-item">
              <span>{{$t("authority.doctor")}}：</span>
              <span>{{$route.query.doctor}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-title clearfix">
          <span class="fl circle">02</span>
          <span class="fl text">{{$t("authority.service")}}</span>
        </div>
        <div class="section-wrapper">
          <div class="service">
            <div class="service-title">{{$t("authority.analysis")}}</div>
            <div class="service-wrapper">
              <div  class="service-item clearfix">
                <div class="fl left">
                  <el-form-item prop="t1_select">
                    <el-radio-group v-model="formData.t1_select">
                      <el-radio label="t1_238"></el-radio>
                      <el-radio label="t1_118"></el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                
                <div class="fl mr5">
                  <el-form-item prop="t1_limit">
                    <el-input v-model="formData.t1_limit"></el-input>
                  </el-form-item>
                </div>
                <div class="fl use-detail">
                  <span class="mr10">{{$t("authority.total")}}{{t1_range.upper_limit}}</span>
                  <span class="mr10">{{$t("authority.remaining")}}{{t1_range.unallocated}}</span>
                  <span class="mr10">{{$t("authority.Used")}}{{t1_range.lower_limit}}</span>
                </div>
              </div>
              <div  class="service-item clearfix">
                <div class="fl left">
                  <el-form-item prop="pet_checked">
                    <el-checkbox name="type" v-model="formData.pet_checked">VMAT2-PET</el-checkbox>
                  </el-form-item>
                </div>
                
                <div class="fl mr5">
                  <el-form-item prop="pet_limit">
                    <el-input v-model="formData.pet_limit"></el-input>
                  </el-form-item>
                </div>
                <div class="fl use-detail">
                  <span class="mr10">{{$t("authority.total")}}{{pet_range.upper_limit}}</span>
                  <span class="mr10">{{$t("authority.remaining")}}{{pet_range.unallocated}}</span>
                  <span class="mr10">{{$t("authority.Used")}}{{pet_range.lower_limit}}</span>
                </div>
              </div>
              <div  class="service-item clearfix">
                <div class="fl left">
                  <el-form-item prop="dti_checked">
                    <el-checkbox name="type" v-model="formData.dti_checked">DTI</el-checkbox>
                  </el-form-item>
                </div>
                
                <div class="fl mr5">
                  <el-form-item prop="dti_limit">
                    <el-input v-model="formData.dti_limit"></el-input>
                  </el-form-item>
                </div>
                <div class="fl use-detail">
                  <span class="mr10">{{$t("authority.total")}}{{dti_range.upper_limit}}</span>
                  <span class="mr10">{{$t("authority.remaining")}}{{dti_range.unallocated}}</span>
                  <span class="mr10">{{$t("authority.Used")}}{{dti_range.lower_limit}}</span>
                </div>
              </div>
              <div  class="service-item clearfix">
                <div class="fl left">
                  <el-form-item prop="vbm_checked">
                    <el-checkbox name="type" v-model="formData.vbm_checked">VBM</el-checkbox>
                  </el-form-item>
                </div>
                
                <div class="fl mr5">
                  <el-form-item prop="vbm_limit">
                    <el-input v-model="formData.vbm_limit"></el-input>
                  </el-form-item>
                </div>
                <div class="fl use-detail">
                  <span class="mr10">{{$t("authority.total")}}{{vbm_range.upper_limit}}</span>
                  <span class="mr10">{{$t("authority.remaining")}}{{vbm_range.unallocated}}</span>
                  <span class="mr10">{{$t("authority.Used")}}{{vbm_range.lower_limit}}</span>
                </div>
              </div>
              <div  class="service-item clearfix">
                <div class="fl left">
                  <el-form-item prop="tbss_checked">
                    <el-checkbox name="type" v-model="formData.tbss_checked">TBSS</el-checkbox>
                  </el-form-item>
                </div>
                
                <div class="fl mr5">
                  <el-form-item prop="tbss_limit">
                    <el-input v-model="formData.tbss_limit"></el-input>
                  </el-form-item>
                </div>
                <div class="fl use-detail">
                  <span class="mr10">{{$t("authority.total")}}{{tbss_range.upper_limit}}</span>
                  <span class="mr10">{{$t("authority.remaining")}}{{tbss_range.unallocated}}</span>
                  <span class="mr10">{{$t("authority.Used")}}{{tbss_range.lower_limit}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="service analysis">
            <div class="service-title">{{$t("authority.project_application")}}</div>
            <div class="service-wrapper">
              <div  class="service-item clearfix">
                <div class="fl left">
                  <el-form-item prop="nifti_download">
                    <el-checkbox name="type" v-model="formData.nifti_download">{{$t("authority.nifti_download")}}</el-checkbox>
                  </el-form-item>
                </div>
              </div>
              <div  class="service-item clearfix">
                <div class="fl left">
                  <el-form-item prop="dicom_download">
                    <el-checkbox name="type" v-model="formData.dicom_download">{{$t("authority.dicom_download")}}</el-checkbox>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button type="button" class="btn btn-primary" @click.prevent="submitAuthority('authorForm')">{{$t("save")}}</button>
      </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { studentLimits, setStudentAuthority } from "../../api/api";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      formData: {
        t1_select: "",
        t1_limit: "",
        pet_checked: "",
        pet_limit: "",
        dti_checked: "",
        dti_limit: "",
        vbm_checked: "",
        vbm_limit: "",
        tbss_checked: "",
        tbss_limit: "",
        nifti_download: "",
        dicom_download: "",
        dicom_view: "",
        batch_upload: "",
        project_group: "",
      },
      t1_range: {},
      dti_range: {},
      pet_range: {},
      vbm_range: {},
      tbss_range: {},
      id:this.$route.query.id || ""
    };
  },
  computed: {
    rules() {
      return {
        // t1_select: [
        //   { required: true, message: this.$t("login_failure"), trigger: 'change' }
        // ],
        // t1_limit: [
        //   { required: true, message: '请输入脑区数量', trigger: 'change' }
        // ],
      }
    }
  },
  created() {
    this.getAccountInfo()
  },
  methods: {
    async getAccountInfo() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const _self = this
      try {
        const res = await studentLimits({id:this.id});
        if (res.status === 1) {
          const accountInfo = res.data ? res.data : {};
          _self.initRanges(accountInfo)
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        this.$helper.errNotify(error.message);
      }
    },
    initRanges(ranges) {
      this.t1_range = ranges.service_set.t1.range || {} 
      this.dti_range = ranges.service_set.dti.range || {}
      this.pet_range = ranges.service_set.pet.range || {}
      this.vbm_range = ranges.service_set.vbm.range || {}
      this.tbss_range = ranges.service_set.tbss.range || {}
    },
    submitAuthority(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSubmitAuthority();
        } else {
          return false;
        }
      });
    },
    async handleSubmitAuthority() {
      const _self = this;
      const params = {
        id: this.id,
        service_set: {
          t1:{
            select: this.formData.t1_select,
            limit: this.formData.t1_limit,
            t1_238:{
              checked: this.formData.t1_select === "t1_238" ? true : false
            },
            t1_118:{
              checked: this.formData.t1_select === "t1_118" ? true : false
            }
          },
          pet:{
            checked: this.formData.pet_checked,
            limit: this.formData.pet_limit
          },
          dti:{
            checked: this.formData.dti_checked,
            limit: this.formData.dti_limit
          },
          vbm:{
            checked: this.formData.vbm_checked,
            limit: this.formData.vbm_limit
          },
          tbss:{
            checked: this.formData.tbss_checked,
            limit: this.formData.tbss_limit
          }
        },
        project_set: {
          nifti_download:{
            checked: this.formData.nifti_download
          },
          dicom_download:{
            checked: this.formData.dicom_download
          },
          batch_upload:{
            checked: true
          },
          dicom_view:{
            checked: true
          },
          project_group:{
            checked: true
          }
        }
      }
      console.log(params)
      try {
        const res = await setStudentAuthority(params);
        if (res.status === 1) {
            this.$helper.successNotify(res.message, function() {
            _self.$router.push("/student")
          }, 1000);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    }
  }
};
</script>

<style lang="less">
.authority-content {
  padding: 20px 40px;
  background: #fff;
  border: 1px solid #dee2e6;
  .content-title {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
    font-size: 20px;
    color: #212528;
  }
  .section-title {
    padding: 5px 15px;
    background: #f2f7fa;
    .circle {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: #000;
      color: #fff;
      line-height: 20px;
      text-align: center;
      border-radius: 100%;
    }
    .text {
      height: 20px;
      line-height: 20px;
    }
  }
  .section-wrapper {
    padding: 30px 0 30px 30px;
    label{
      margin-bottom: 0;
    }
    .info-item {
      margin-right: 80px;
      height: 36px;
      line-height: 36px;
      &:last-child {
        margin-right: 0;
      }
      .info-input {
        display: inline-block;
        padding: 0 10px;
        border: 1px solid #ced4da;
        border-radius: 5px;
        height: 36px;
        width: 200px;
      }
    }
    .service {
      margin-bottom: 30px;
    }
    .service-title {
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dee2e6;
      font-size: 14px;
      color: #212528;
    }
    .analysis .service-item {
      margin-bottom: 0;
    }
    .service-item {
      // margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .left {
        margin-right: 30px;
        // height: 36px;
        // line-height: 36px;
      }
      .use-detail{
        height: 32px;
        line-height: 32px;
      }
      .service-input {
        display: inline-block;
        padding: 0 10px;
        width: 100px;
        height: 36px;
        border: 1px solid #ced4da;
        border-radius: 5px;
      }
    }
  }
  .button-wrapper {
    margin-top: 30px;
    .btn-default {
      margin-right: 20px;
      border: 1px solid #ced4da;
    }
  }
}
</style>
